import React, {useState, useEffect} from 'react';
import '../assets/scss/index.scss';

const BaseLayout = ({children}) => {
    const [loaded, setLoaded] = useState(false)
    const [fontsLoaded, setFontsLoaded] = useState(false)
    const [allowedCountry, setAllowedCountry] = useState(false)
    
    useEffect(() => {
        const blockedCountryCodes = [];
        const token = '3023b3862b2dce'
        fetch(`https://ipinfo.io/json?token=${token}`)
            .then(response => response.json())
            .then(response => {
                const countryCode = response.country;
                if (blockedCountryCodes.includes(countryCode) && !window.location.pathname.includes('/blocked')) {
                    window.location.replace('/blocked');
                } else {
                    setAllowedCountry(true)
                }
            })
            .catch(error => {
                console.log("error", error);
                window.location.replace('/blocked');
            });
    }, [])
    
    useEffect(() => {
        document.fonts.ready.then(() => {
            setFontsLoaded(true)
        })
        setLoaded(true)
    }, [])

    return (
        <div className={`base-layout${loaded && fontsLoaded ? ' loaded' : ''}`}>
            {allowedCountry && children}
        </div>
    )
}

export default BaseLayout;
