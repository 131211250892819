import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Mousewheel, Navigation} from 'swiper'

import Image from '../../elements/Image'
import Observe from '../../elements/Observe';
import CapsUnderline from '../../elements/CapsUnderline'

import InstagramPosts from '../../../jsons/home/instagram-posts.json'

const Instagram = () => {
	return (
		<section className="instagram">
			<div className="instagram__container">
				<Observe>
					<div className="instagram__container--head">
						<a href="https://www.instagram.com/thoagency/" target="_blank" rel="noreferrer">
							<p>INSTAGRAM</p>
							<Image src="/svgs/instagram-dark.svg" alt="instagram-icon" width={34} height={34}/>
						</a>
						<CapsUnderline lineType="bottom" color="pink">Back of House</CapsUnderline>
					</div>
				</Observe>
				<div className="instagram__posts">
					<Swiper
						spaceBetween={25}
						mousewheel={true}
						navigation={true}
						speed={700}
						breakpoints={{
							325: {
								slidesPerView: 2,
							},
							1024: {
								slidesPerView: 4,
							},
						}}
						modules={[Mousewheel, Navigation]}
					>
						{InstagramPosts.map((post, i) => {
							return (
								<SwiperSlide key={`${i}-instagram-post`}>
									<a>
										<Image src={post.thumbnail} alt={`${i}-instagram`}/>
									</a>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</div>
			</div>
		</section>
	)
}

export default Instagram
