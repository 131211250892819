import React, { useEffect, useState } from 'react'
import Footer from '../layouts/Footer'
import Hero from '../components/pages/home/Hero'
import HouseVideo from '../components/pages/home/HouseVideo'
import Partners from '../components/pages/home/Partners'
import WorkShowcase from '../components/pages/home/WorkShowcase'
import BaseLayout from '../layouts/BaseLayout'
import Layout from '../layouts/Layout'
import OurYourHouse from '../components/pages/home/OurYourHouse'
import Instagram from '../components/pages/home/Instagram'
import SafariDetect from '../components/elements/SafariDetect'

const IndexPage = () => {
  const [scrollHeight, setScrollHeight] = useState(0)
  useEffect(() => {
    const stateToggler = () => setScrollHeight(window.scrollY)
    window.addEventListener('scroll', stateToggler)
    return () => window.removeEventListener('scroll', stateToggler)
  }, [])
  return (
    <BaseLayout>
      <Layout>
        <main className={`home-wrapper`}>
          <Hero scrollHeight={scrollHeight} setScrollHeight={setScrollHeight} />
          <HouseVideo />
          <SafariDetect>
            <OurYourHouse />
          </SafariDetect>
          <WorkShowcase />
          <Partners />
          <Instagram />
          <Footer />
        </main>
      </Layout>
    </BaseLayout>
  )
}

export default IndexPage
